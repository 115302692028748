import moment from 'moment'
import React, { useEffect } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { licensesSelectors } from '../../../..'
import { gaImages } from '../../../../../../../assets/globalConstants'
import LightButton from '../../../../../../../Components/atoms/buttons/LightButton/LightButton'
import GaImage from '../../../../../../../Components/atoms/Image/Image'
import LightPanel from '../../../../../../../Components/atoms/panels/LightPanel/LightPanel'
import Description from '../../../../../../../Components/atoms/texts/Description/Description'
import CardListSkeleton from '../../../../../../../Components/molecules/skeletons/cardListSkeleton/CardListSkeleton'
import LicenseCard from '../../../../../../../Components/organisms/Cards/LicenseCard/LicenseCard'
import {
    IPriceModel,
    ITierModel,
    StepModel,
} from '../../../../../../../interfaces/interfaces'
import {
    formatDate,
    formatStringDate,
} from '../../../../../../../utils/formatDataUtil'
import { showScreenLoaderAction } from '../../../../../Loader/loaderActions'
import { setSelectedRoute } from '../../../../../PanelScafolding/scafoldingActions'
import { popularLicenseType } from '../../../../licenses.constants'
import { setCurrentStepAction } from '../../../../licensesActions'
import DownGradeInfoModal from '../../downGradeInfoModal/DownGradeInfoModal'
import FeaturesModal from '../../featuresModal/FeaturesModal'
import styles from './changeSubscriptionFirstStep.module.scss'
import { statsSelectors } from '../../../../../Statistics'
import cx from 'classnames'
import {
    getIfProductHasAmount,
    getIfProductHasTrialDays,
    getTierLimitToDisplay,
    getTierLimitsValues,
    isEnterpriseTier,
    licenseIsLowerThanCurrent,
} from '../../../../../../../utils/licensesUtil'
import GaRadioButton from '../../../../../../../Components/atoms/formFields/radioButton/GaRadioButton'
import GaHoverTooltip from '../../../../../../../Components/molecules/tooltips/GaHoverTooltip/GaHoverTooltip'
import { TooltipPosition } from '../../../../../../../data/globalVar'
import { isDefaultBrandActive } from '../../../../../../../utils/brandThemeUtil'
import { Feature } from '../../../../../../../Components/organisms/Cards/OnboardingLicenseCard/gatacaOnboardingLicenseCard/GatacaOnboardingLicenseCard'

type IChangeSubscriptionFirstStepProps = {
    switchPeriodValue: string
    flowSteps: StepModel[]
    updateLicense: () => void
    setPeriodChecked: (x?) => void
    setIsNextStepActive: (x?) => void
    setNewLicense: (x?) => void
    goToNextStep: (x?) => void
    hideDowngradeInfoModal: (x?) => void
    getPrice: (x?, y?) => number | undefined
    currentLicense
    showDowngradeInfoModal: boolean
    newLicense
}

const ChangeSubscriptionFirstStep: React.FC<
    IChangeSubscriptionFirstStepProps
> = (props) => {
    const {
        switchPeriodValue,
        showDowngradeInfoModal,
        newLicense,
        flowSteps,
        updateLicense,
        getPrice,
        goToNextStep,
        hideDowngradeInfoModal,
        setNewLicense,
        setIsNextStepActive,
        setPeriodChecked,
    } = props
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const history = useHistory()
    const subscriptionIsUpdating = useSelector(
        licensesSelectors.updatingSubscriptionLoading
    )

    useEffect(() => {
        if (!subscriptionIsUpdating) {
            dispatch(setCurrentStepAction(flowSteps[0]))
        }
    }, [])

    const downGradeToOtherSubscriptionNotFree = (license: ITierModel) => {
        setNewLicense(license)
    }

    const cancelSubscription = (license) => {
        setNewLicense(
            tiers?.find((el) => el.licenseType?.toLowerCase() === 'starter')
        )
    }
    const currentLicense = useSelector(licensesSelectors.getCurrentProduct)
    const [showAllFeatures, setShowAllFeaturesModal] = React.useState(false)
    const showAllFeaturesModal = () => setShowAllFeaturesModal(true)
    const hideAllFeaturesModal = () => setShowAllFeaturesModal(false)
    const subscriptionInfo = useSelector(licensesSelectors.getSubscriptionInfo)
    const currentSubscriptionPeriod = useSelector(
        licensesSelectors.getCurrentSubscriptionPeriod
    )
    const usedFeatures = useSelector(statsSelectors.getUsedFeaturesNumber)
    const currentSubscriptionPeriodIsYear = currentSubscriptionPeriod === 'year'
    const newLicenseIsStarter = getIfProductHasTrialDays(
        newLicense,
        switchPeriodValue
    )
    const currentIsEqualToNewLicense = newLicense?.id === currentLicense?.id
    const newLicensePeriod = useSelector(licensesSelectors.getNewLicensePeriod)
    const tiers = useSelector(licensesSelectors.getProviderTiersAndFeatures)

    const newIsLowerThanCurrent = licenseIsLowerThanCurrent(
        newLicense,
        currentLicense,
        currentSubscriptionPeriod,
        switchPeriodValue
    )
    const isUpgrade =
        (!newIsLowerThanCurrent && !currentIsEqualToNewLicense) ||
        (currentIsEqualToNewLicense && currentSubscriptionPeriod === 'month')
    const newLicenseDate = formatStringDate(
        subscriptionInfo?.currentPeriodEnd,
        'DD/MM/YYYY'
    )

    useEffect(() => {
        if (currentLicense && newLicense) {
            setIsNextStepActive(true)
        }
    }, [currentLicense, newLicense])

    useEffect(() => {
        setPeriodChecked(switchPeriodValue)
    }, [currentLicense])

    const availableProducts = () => {
        return tiers?.filter((tier) => {
            return tier?.id !== currentLicense?.id
        })
    }

    const getNextSubscriptionInitDate = () => {
        const initDate = new Date()
        const oneYearAgoDate = moment().add(12, 'M').format('DD/MM/YYYY')
        // Iniciar en este año, este mes, en el día 1
        const oneMonthAgoDate = new Date(
            initDate.getFullYear(),
            initDate.getMonth() + 1,
            1
        )

        return currentSubscriptionPeriodIsYear
            ? oneYearAgoDate
            : formatDate(oneMonthAgoDate)
    }

    const getPriceForSameLicense = (prices: IPriceModel[]) => {
        const selectedPeriodPrice = prices?.filter((el) => {
            return el.recurringInterval !== currentSubscriptionPeriod
        })[0]

        return selectedPeriodPrice?.amount
            ? selectedPeriodPrice?.amount / 100
            : 0
    }

    const downgradeCancelFunction = () => {
        dispatch(showScreenLoaderAction())
        updateLicense()
        dispatch(setSelectedRoute('subscription')), history.push('subscription')
    }

    const limitToDisplay = (tierLimit?: number | string) => {
        return getTierLimitToDisplay(tierLimit)
    }

    return (
        <div className={styles.changeSubscriptionContainer}>
            <div className={styles.changeSubscriptionContainer__licenses}>
                <div className={styles.changeSubscriptionFirstStep}>
                    <div
                        className={
                            styles.changeSubscriptionFirstStep__licensesContainer
                        }
                    >
                        {currentLicense?.id ? (
                            <LicenseCard
                                className={
                                    styles.changeSubscriptionFirstStep__licenseCard
                                }
                                isDefaultBrandActive={isDefaultBrandActive()}
                                subscriptionInfo={subscriptionInfo}
                                downgradeSubscriptionFunction={
                                    downGradeToOtherSubscriptionNotFree
                                }
                                getPrice={getPrice}
                                license={currentLicense}
                                // secondButton={{
                                //     label: 'subscription.seeAllFeatures',
                                //     function: showAllFeaturesModal,
                                // }}
                                showAllDetails
                                showCurrentLicenseTag
                                currentLicensePeriod={
                                    currentSubscriptionPeriod || ''
                                }
                                newLicensePeriod={newLicensePeriod || ''}
                                period={currentSubscriptionPeriod || ''}
                                isCurrentLicense={true}
                                isNewLicense={false}
                                lowerThanCurrent={false}
                                cancelSubscriptionFunction={cancelSubscription}
                            />
                        ) : (
                            <div
                                className={
                                    styles.changeSubscriptionFirstStep__licenseSkeleton
                                }
                            >
                                <CardListSkeleton cardsNumber={2} />
                            </div>
                        )}
                        <GaImage
                            className={styles.rightArrow}
                            image={gaImages.rightArrow}
                        />
                        <GaImage
                            className={styles.downArrow}
                            image={gaImages.rightArrow}
                        />

                        <LicenseCard
                            className={
                                styles.changeSubscriptionFirstStep__licenseCard
                            }
                            isDefaultBrandActive={isDefaultBrandActive()}
                            subscriptionInfo={subscriptionInfo}
                            downgradeSubscriptionFunction={
                                downGradeToOtherSubscriptionNotFree
                            }
                            getPrice={getPrice}
                            currentLicensePeriod={
                                currentSubscriptionPeriod || ''
                            }
                            newLicensePeriod={newLicensePeriod || ''}
                            license={newLicense}
                            // COMEMNTED because this will be restored in the future
                            // secondButton={{
                            //     label: 'subscription.seeAllFeatures',
                            //     function: showAllFeaturesModal,
                            // }}
                            showAllDetails
                            period={
                                newLicense?.id !== currentLicense?.id
                                    ? switchPeriodValue
                                    : currentSubscriptionPeriodIsYear
                                    ? 'month'
                                    : 'year'
                            }
                            isCurrentLicense={false}
                            isNewLicense={true}
                            lowerThanCurrent={true}
                            cancelSubscriptionFunction={cancelSubscription}
                        />
                    </div>
                    {getIfProductHasAmount(newLicense, switchPeriodValue) ? (
                        <div className={styles.next}>
                            <p
                                className={cx(
                                    'heading6 neutral1000',
                                    styles.next__title
                                )}
                            >
                                {t('subscription.whatHappensNext')}
                            </p>
                            <ul className={styles.next__list}>
                                <li
                                    className={cx(
                                        'bodyRegularXS neutral700',
                                        styles.next__list__item
                                    )}
                                >
                                    <Trans
                                        className={cx(
                                            'bodyRegularXS neutral700'
                                        )}
                                        defaults={
                                            isUpgrade
                                                ? 'subscription.newSubscriptionStarts'
                                                : 'subscription.yourNewSubscriptionDate'
                                        }
                                        values={{ date: newLicenseDate }}
                                    />
                                </li>

                                {
                                    <li
                                        className={cx(
                                            'bodyRegularXS neutral700',
                                            styles.next__list__item
                                        )}
                                    >
                                        <Trans
                                            className={cx(
                                                'bodyRegularXS neutral700'
                                            )}
                                            defaults={
                                                isUpgrade
                                                    ? 'subscription.newPaymentToday'
                                                    : 'subscription.newPaymentDate'
                                            }
                                            values={{
                                                date: getNextSubscriptionInitDate(),
                                                price: getPrice(
                                                    newLicense?.prices,
                                                    switchPeriodValue
                                                )
                                                    ? getPrice(
                                                          newLicense?.prices,
                                                          switchPeriodValue
                                                      ) +
                                                      '€/' +
                                                      t(
                                                          'subscription.' +
                                                              switchPeriodValue
                                                      )?.toLowerCase()
                                                    : t(
                                                          'susbcription.customPricing'
                                                      ),
                                            }}
                                            components={{ bold: <strong /> }}
                                        />
                                    </li>
                                }
                                {isUpgrade && (
                                    <li
                                        className={cx(
                                            'bodyRegularXS neutral700',
                                            styles.next__list__item
                                        )}
                                    >
                                        {t('subscription.disscount')}
                                    </li>
                                )}
                            </ul>
                        </div>
                    ) : null}
                </div>
                <div className={styles.availableLicenses}>
                    <p
                        className={cx(
                            'heading6 neutral1000',
                            styles.availableLicenses__title
                        )}
                    >
                        {t('subscription.availableSubscriptions')}
                    </p>
                    {availableProducts()?.map((tier, index) => {
                        return isDefaultBrandActive() ? (
                            <LightPanel className={styles.license}>
                                <GaRadioButton
                                    id={'period'}
                                    index={'' + index}
                                    name={'period'}
                                    value={'month'}
                                    checked={tier === newLicense}
                                    onChange={() => {
                                        setNewLicense(tier)
                                    }}
                                />
                                <div>
                                    <div className={styles.license__header}>
                                        <p
                                            className={cx(
                                                'heading6 neutral1000',
                                                styles.license__header__title
                                            )}
                                        >
                                            {tier?.name}
                                        </p>
                                        {tier?.type === popularLicenseType && (
                                            <div
                                                className={
                                                    styles.license__header__popularTag
                                                }
                                            >
                                                <p>
                                                    {t('subscription.popular')}
                                                </p>
                                            </div>
                                        )}
                                    </div>
                                    {tier.description && (
                                        <Description
                                            className={
                                                styles.license__description
                                            }
                                            text={tier.description}
                                        />
                                    )}
                                    <p
                                        className={cx(
                                            'bodyRegularMD neutral700',
                                            styles.license__price
                                        )}
                                    >
                                        {!!getPrice(
                                            tier?.prices,
                                            switchPeriodValue
                                        ) ? (
                                            <>
                                                <span
                                                    className={cx(
                                                        'heading3 neutral1000'
                                                    )}
                                                >
                                                    {getPrice(
                                                        tier?.prices,
                                                        switchPeriodValue
                                                    )}
                                                    €
                                                </span>{' '}
                                                &nbsp; /{' '}
                                                {t(
                                                    'subscription.' +
                                                        switchPeriodValue
                                                )}
                                            </>
                                        ) : (
                                            <span
                                                className={cx(
                                                    'heading3 neutral1000'
                                                )}
                                            >
                                                {t(
                                                    'subscription.customPricing'
                                                )}
                                            </span>
                                        )}
                                    </p>
                                    <div className={styles.license__features}>
                                        <div>
                                            {!isEnterpriseTier(tier) ? (
                                                <ul
                                                    className={
                                                        styles.license__features__list
                                                    }
                                                >
                                                    <Feature
                                                        feature={
                                                            getTierLimitsValues(
                                                                tier
                                                            )?.activeUsers
                                                        }
                                                        label={
                                                            'subscription.activeUsers'
                                                        }
                                                    />
                                                    <Feature
                                                        feature={
                                                            getTierLimitsValues(
                                                                tier
                                                            )?.issuedCredentials
                                                        }
                                                        label={
                                                            'subscription.issuedCredentials'
                                                        }
                                                    />
                                                    <Feature
                                                        feature={''}
                                                        label={
                                                            'subscription.standardSupport'
                                                        }
                                                    />
                                                </ul>
                                            ) : (
                                                <ul
                                                    className={
                                                        styles.license__features__list
                                                    }
                                                >
                                                    <Feature
                                                        feature={''}
                                                        label={
                                                            'subscription.customLimits'
                                                        }
                                                    />
                                                    <Feature
                                                        feature={''}
                                                        label={
                                                            'subscription.advancedFunctionalities'
                                                        }
                                                    />
                                                    <Feature
                                                        feature={''}
                                                        label={
                                                            'subscription.premiumSupport'
                                                        }
                                                    />
                                                </ul>
                                            )}
                                        </div>
                                    </div>
                                    <LightButton
                                        className={styles.license__button}
                                        functionality={showAllFeaturesModal}
                                        text={'subscription.seeAllFeatures'}
                                        disabled={false}
                                    />
                                </div>
                            </LightPanel>
                        ) : (
                            <LightPanel className={styles.license}>
                                <GaRadioButton
                                    id={'period'}
                                    index={'' + index}
                                    name={'period'}
                                    value={'month'}
                                    checked={tier === newLicense}
                                    onChange={() => {
                                        setNewLicense(tier)
                                    }}
                                />
                                <div>
                                    <div className={styles.license__header}>
                                        <p
                                            className={cx(
                                                'heading6 neutral1000',
                                                styles.license__header__title
                                            )}
                                        >
                                            {tier?.name}
                                        </p>
                                        {tier?.type === popularLicenseType && (
                                            <div
                                                className={
                                                    styles.license__header__popularTag
                                                }
                                            >
                                                <p>
                                                    {t('subscription.popular')}
                                                </p>
                                            </div>
                                        )}
                                    </div>
                                    {tier.description && (
                                        <Description
                                            className={
                                                styles.license__description
                                            }
                                            text={tier.description}
                                        />
                                    )}
                                    <p
                                        className={cx(
                                            'bodyRegularMD neutral700',
                                            styles.license__price
                                        )}
                                    >
                                        {!!getPrice(
                                            tier?.prices,
                                            switchPeriodValue
                                        ) ? (
                                            <>
                                                <span
                                                    className={cx(
                                                        'heading3 neutral1000'
                                                    )}
                                                >
                                                    {getPrice(
                                                        tier?.prices,
                                                        switchPeriodValue
                                                    )}
                                                    €
                                                </span>{' '}
                                                &nbsp; /{' '}
                                                {t(
                                                    'subscription.' +
                                                        switchPeriodValue
                                                )}
                                            </>
                                        ) : (
                                            <span
                                                className={cx(
                                                    'heading3 neutral1000'
                                                )}
                                            >
                                                {t(
                                                    'subscription.customPricing'
                                                )}
                                            </span>
                                        )}
                                    </p>
                                    <div className={styles.license__features}>
                                        <div>
                                            <p
                                                className={cx(
                                                    'bodyBoldXS neutral700',
                                                    styles.license__features__title
                                                )}
                                            >
                                                {t('subscription.features')}
                                            </p>
                                            <ul
                                                className={
                                                    styles.license__features__list
                                                }
                                            >
                                                <li
                                                    className={
                                                        styles.license__features__list__item
                                                    }
                                                >
                                                    <GaImage
                                                        image={gaImages.check}
                                                    />{' '}
                                                    <span
                                                        className={cx(
                                                            'bodyBoldMD neutral1000'
                                                        )}
                                                    >
                                                        {limitToDisplay(
                                                            getTierLimitsValues(
                                                                tier
                                                            )
                                                                ?.verificationTemplates
                                                        )}
                                                    </span>
                                                    <span
                                                        className={cx(
                                                            'bodyRegularSM neutral1000'
                                                        )}
                                                    >
                                                        {t(
                                                            'subscription.verificationTemplates'
                                                        )}
                                                    </span>
                                                </li>
                                                <li
                                                    className={
                                                        styles.license__features__list__item
                                                    }
                                                >
                                                    <GaHoverTooltip
                                                        position={
                                                            TooltipPosition.bottom
                                                        }
                                                        label={
                                                            <>
                                                                <GaImage
                                                                    image={
                                                                        gaImages.check
                                                                    }
                                                                />
                                                                <span
                                                                    className={cx(
                                                                        'bodyBoldMD neutral1000',
                                                                        styles.featureQuantity
                                                                    )}
                                                                >
                                                                    {limitToDisplay(
                                                                        getTierLimitsValues(
                                                                            tier
                                                                        )
                                                                            ?.activeUsers
                                                                    )}
                                                                </span>
                                                                <p
                                                                    className={cx(
                                                                        'bodyRegularSM neutral1000',
                                                                        styles.featureName
                                                                    )}
                                                                >
                                                                    {t(
                                                                        'subscription.activeUsers'
                                                                    )}
                                                                </p>
                                                            </>
                                                        }
                                                        info={t(
                                                            'subscription.activeUsersTooltipFree'
                                                        )}
                                                    />
                                                </li>
                                            </ul>
                                        </div>
                                        <div>
                                            <p
                                                className={cx(
                                                    'bodyBoldXS neutral700',
                                                    styles.license__features__title
                                                )}
                                            >
                                                {t(
                                                    'subscription.issuanceFeatures'
                                                )}
                                            </p>
                                            <ul
                                                className={
                                                    styles.license__features__list
                                                }
                                            >
                                                <li
                                                    className={
                                                        styles.license__features__list__item
                                                    }
                                                >
                                                    <GaImage
                                                        image={gaImages.check}
                                                    />{' '}
                                                    <span
                                                        className={cx(
                                                            'bodyBoldMD neutral1000'
                                                        )}
                                                    >
                                                        {limitToDisplay(
                                                            getTierLimitsValues(
                                                                tier
                                                            )?.issuanceTemplates
                                                        )}
                                                    </span>
                                                    <span
                                                        className={cx(
                                                            'bodyRegularSM neutral1000'
                                                        )}
                                                    >
                                                        {t(
                                                            'subscription.issuanceTemplates'
                                                        )}
                                                    </span>
                                                </li>
                                                <li
                                                    className={
                                                        styles.license__features__list__item
                                                    }
                                                >
                                                    <GaHoverTooltip
                                                        position={
                                                            TooltipPosition.bottom
                                                        }
                                                        label={
                                                            <>
                                                                <GaImage
                                                                    image={
                                                                        gaImages.check
                                                                    }
                                                                />
                                                                <span
                                                                    className={cx(
                                                                        'bodyBoldMD neutral1000',
                                                                        styles.featureQuantity
                                                                    )}
                                                                >
                                                                    {limitToDisplay(
                                                                        getTierLimitsValues(
                                                                            tier
                                                                        )
                                                                            ?.issuedCredentials
                                                                    )}
                                                                </span>
                                                                <p
                                                                    className={cx(
                                                                        'bodyRegularSM neutral1000',
                                                                        styles.featureName
                                                                    )}
                                                                >
                                                                    {t(
                                                                        'subscription.issuedCredentials'
                                                                    )}
                                                                </p>
                                                            </>
                                                        }
                                                        info={t(
                                                            'subscription.issuedCredentialsTooltipFree'
                                                        )}
                                                    />
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    {/* COMEMNTED because this will be restored in the future
                                    <LightButton
                                        className={styles.license__button}
                                        functionality={showAllFeaturesModal}
                                        text={'subscription.seeAllFeatures'}
                                        disabled={false}
                                    /> */}
                                </div>
                            </LightPanel>
                        )
                    })}

                    {!getIfProductHasTrialDays(
                        currentLicense,
                        switchPeriodValue
                    ) ? (
                        isDefaultBrandActive() ? (
                            <LightPanel className={styles.license}>
                                <GaRadioButton
                                    id={'period'}
                                    index={'cl'}
                                    name={'period'}
                                    value={'month'}
                                    checked={currentLicense === newLicense}
                                    onChange={() => {
                                        setNewLicense(currentLicense)
                                    }}
                                />
                                <div>
                                    <div className={styles.license__header}>
                                        <p
                                            className={cx(
                                                'heading6 neutral1000',
                                                styles.license__header__title
                                            )}
                                        >
                                            {currentLicense?.name}
                                        </p>
                                    </div>
                                    {currentLicense.description && (
                                        <Description
                                            className={
                                                styles.license__description
                                            }
                                            text={currentLicense.description}
                                        />
                                    )}
                                    <p
                                        className={cx(
                                            'bodyRegularMD neutral700',
                                            styles.license__price
                                        )}
                                    >
                                        {!!getPrice(
                                            currentLicense?.prices,
                                            switchPeriodValue
                                        ) ? (
                                            <>
                                                <span
                                                    className={cx(
                                                        'heading3 neutral1000'
                                                    )}
                                                >
                                                    {getPrice(
                                                        currentLicense?.prices,
                                                        switchPeriodValue
                                                    )}
                                                    €
                                                </span>{' '}
                                                &nbsp; /{' '}
                                                {t(
                                                    'subscription.' +
                                                        switchPeriodValue
                                                )}
                                            </>
                                        ) : (
                                            <span
                                                className={cx(
                                                    'heading3 neutral1000'
                                                )}
                                            >
                                                {t(
                                                    'subscription.customPricing'
                                                )}
                                            </span>
                                        )}
                                    </p>
                                    <div className={styles.license__features}>
                                        <div>
                                            {!isEnterpriseTier(
                                                currentLicense
                                            ) ? (
                                                <ul
                                                    className={
                                                        styles.license__features__list
                                                    }
                                                >
                                                    <Feature
                                                        feature={
                                                            getTierLimitsValues(
                                                                currentLicense
                                                            )?.activeUsers
                                                        }
                                                        label={
                                                            'subscription.activeUsers'
                                                        }
                                                    />
                                                    <Feature
                                                        feature={
                                                            getTierLimitsValues(
                                                                currentLicense
                                                            )?.issuedCredentials
                                                        }
                                                        label={
                                                            'subscription.issuedCredentials'
                                                        }
                                                    />
                                                    <Feature
                                                        feature={''}
                                                        label={
                                                            'subscription.standardSupport'
                                                        }
                                                    />
                                                </ul>
                                            ) : (
                                                <ul
                                                    className={
                                                        styles.license__features__list
                                                    }
                                                >
                                                    <Feature
                                                        feature={''}
                                                        label={
                                                            'subscription.customLimits'
                                                        }
                                                    />
                                                    <Feature
                                                        feature={''}
                                                        label={
                                                            'subscription.advancedFunctionalities'
                                                        }
                                                    />
                                                    <Feature
                                                        feature={''}
                                                        label={
                                                            'subscription.premiumSupport'
                                                        }
                                                    />
                                                </ul>
                                            )}
                                        </div>
                                    </div>
                                    <LightButton
                                        className={styles.license__button}
                                        functionality={showAllFeaturesModal}
                                        text={'subscription.seeAllFeatures'}
                                        disabled={false}
                                    />
                                </div>
                            </LightPanel>
                        ) : (
                            <LightPanel className={styles.license}>
                                <GaRadioButton
                                    id={'period'}
                                    index={'0'}
                                    name={'period'}
                                    value={'month'}
                                    onChange={() => {
                                        setNewLicense(currentLicense)
                                    }}
                                    checked={currentIsEqualToNewLicense}
                                />
                                <div>
                                    <div className={styles.license__header}>
                                        <p
                                            className={cx(
                                                'heading6 neutral700',
                                                styles.license__features__title
                                            )}
                                        >
                                            {currentLicense?.name}
                                        </p>

                                        {!!currentLicense?.isPopular && (
                                            <div
                                                className={
                                                    styles.license__header__popularTag
                                                }
                                            >
                                                <p>
                                                    {t('subscription.popular')}
                                                </p>
                                            </div>
                                        )}
                                    </div>
                                    {currentLicense?.description && (
                                        <Description
                                            className={
                                                styles.license__description
                                            }
                                            text={currentLicense?.description}
                                        />
                                    )}
                                    <p className={styles.license__price}>
                                        <span>
                                            {getPriceForSameLicense(
                                                currentLicense?.prices
                                            )}
                                            €
                                        </span>{' '}
                                        &nbsp;
                                        {/* TODO: TRANSLATE */}/{' '}
                                        {t(
                                            'subscription.' +
                                                (currentSubscriptionPeriodIsYear
                                                    ? 'month'
                                                    : 'year')
                                        )}
                                    </p>
                                    <div className={styles.license__features}>
                                        <div>
                                            <p
                                                className={cx(
                                                    'bodyBoldXS neutral700',
                                                    styles.license__features__title
                                                )}
                                            >
                                                {t('subscription.features')}
                                            </p>
                                            <ul
                                                className={
                                                    styles.license__features__list
                                                }
                                            >
                                                <li
                                                    className={
                                                        styles.license__features__list__item
                                                    }
                                                >
                                                    <GaImage
                                                        image={gaImages.check}
                                                    />
                                                    <span
                                                        className={cx(
                                                            'bodyBoldSM neutral1000'
                                                        )}
                                                    >
                                                        {
                                                            getTierLimitsValues(
                                                                currentLicense
                                                            )
                                                                ?.verificationTemplates
                                                        }
                                                    </span>
                                                    <span>
                                                        {t(
                                                            'subscription.verificationTemplates'
                                                        )}
                                                    </span>
                                                </li>
                                                <li
                                                    className={
                                                        styles.license__features__list__item
                                                    }
                                                >
                                                    <GaHoverTooltip
                                                        position={
                                                            TooltipPosition.bottom
                                                        }
                                                        label={
                                                            <>
                                                                <GaImage
                                                                    image={
                                                                        gaImages.check
                                                                    }
                                                                />
                                                                <span
                                                                    className={cx(
                                                                        'bodyBoldMD neutral1000',
                                                                        styles.featureQuantity
                                                                    )}
                                                                >
                                                                    {
                                                                        getTierLimitsValues(
                                                                            currentLicense
                                                                        )
                                                                            ?.activeUsers
                                                                    }
                                                                </span>
                                                                <p
                                                                    className={cx(
                                                                        'bodyRegularSM neutral1000',
                                                                        styles.featureName
                                                                    )}
                                                                >
                                                                    {t(
                                                                        'subscription.activeUsers'
                                                                    )}
                                                                </p>
                                                            </>
                                                        }
                                                        info={t(
                                                            'subscription.activeUsersTooltipFree'
                                                        )}
                                                    />
                                                </li>
                                            </ul>
                                        </div>
                                        <div>
                                            <p
                                                className={cx(
                                                    'bodyBoldXS neutral700',
                                                    styles.license__features__title
                                                )}
                                            >
                                                {t(
                                                    'subscription.issuanceFeatures'
                                                )}
                                            </p>
                                            <ul
                                                className={
                                                    styles.license__features__list
                                                }
                                            >
                                                <li
                                                    className={
                                                        styles.license__features__list__item
                                                    }
                                                >
                                                    <GaImage
                                                        image={gaImages.check}
                                                    />
                                                    <span
                                                        className={cx(
                                                            'bodyBoldSM neutral1000'
                                                        )}
                                                    >
                                                        {
                                                            getTierLimitsValues(
                                                                currentLicense
                                                            )?.issuanceTemplates
                                                        }
                                                    </span>
                                                    <p
                                                        className={cx(
                                                            'bodyRegularSM neutral1000',
                                                            styles.featureName
                                                        )}
                                                    >
                                                        {t(
                                                            'subscription.issuanceTemplates'
                                                        )}
                                                    </p>
                                                </li>
                                                <li
                                                    className={
                                                        styles.license__features__list__item
                                                    }
                                                >
                                                    <GaHoverTooltip
                                                        position={
                                                            TooltipPosition.bottom
                                                        }
                                                        label={
                                                            <>
                                                                <GaImage
                                                                    image={
                                                                        gaImages.check
                                                                    }
                                                                />
                                                                <span
                                                                    className={cx(
                                                                        'bodyBoldMD neutral1000',
                                                                        styles.featureQuantity
                                                                    )}
                                                                >
                                                                    {
                                                                        getTierLimitsValues(
                                                                            currentLicense
                                                                        )
                                                                            ?.issuedCredentials
                                                                    }
                                                                </span>
                                                                <p
                                                                    className={cx(
                                                                        'bodyRegularSM neutral1000',
                                                                        styles.featureName
                                                                    )}
                                                                >
                                                                    {t(
                                                                        'subscription.issuedCredentials'
                                                                    )}
                                                                </p>
                                                            </>
                                                        }
                                                        info={t(
                                                            'subscription.issuedCredentialsTooltipFree'
                                                        )}
                                                    />
                                                </li>
                                            </ul>
                                        </div>
                                    </div>

                                    {/* COMEMNTED because this will be restored in the future
                                    <LightButton
                                        className={styles.license__button}
                                        functionality={showAllFeaturesModal}
                                        text={'subscription.seeAllFeatures'}
                                        disabled={false}
                                    /> */}
                                </div>
                            </LightPanel>
                        )
                    ) : null}
                </div>
            </div>
            {showDowngradeInfoModal ? (
                <DownGradeInfoModal
                    backFunction={() => {
                        hideDowngradeInfoModal()
                    }}
                    continueFunction={
                        !newLicenseIsStarter
                            ? goToNextStep
                            : downgradeCancelFunction
                    }
                    showAllFeaturesModal={showAllFeaturesModal}
                    currentLicense={currentLicense}
                    show={showDowngradeInfoModal}
                    usedFeatures={usedFeatures}
                />
            ) : null}
            {showAllFeatures ? (
                <FeaturesModal
                    show={!!showAllFeatures}
                    switchPeriodValue={switchPeriodValue}
                    hideModal={hideAllFeaturesModal}
                    setPeriodChecked={setPeriodChecked}
                />
            ) : null}
        </div>
    )
}

ChangeSubscriptionFirstStep.defaultProps = {}

export default ChangeSubscriptionFirstStep
