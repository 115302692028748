import cx from 'classnames'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { securityFactorsList } from '../../../../assets/globalConstants'
import { stringfyAsList } from '../../../../utils/formatDataUtil'
import Description from '../../../atoms/texts/Description/Description'
import style from './secondFactorCard.module.scss'
import EditIcon from '../../../../assets/iconsLibrary/simple/edit/editIcon'
import TrashIcon from '../../../../assets/iconsLibrary/simple/trash/trashIcon'

type ISecondFactorCardProps = {
    id?: string
    item?: any
    requiredFactor: string
    backup: string[]
    canEdit?: boolean
    removeFactor: (e) => void
    selectFactor: (e) => void
    openModal: () => void
    editVerifier?: boolean
}

const SecondFactorCard: React.FC<ISecondFactorCardProps> = (props) => {
    const {
        requiredFactor,
        item,
        backup,
        canEdit,
        removeFactor,
        selectFactor,
        openModal,
        editVerifier,
    } = props
    const { t } = useTranslation()
    const requiredFactortext = securityFactorsList.find(
        (e) => e.value === requiredFactor
    )?.text

    const filteredScopes = securityFactorsList?.filter(
        (el) => !!backup?.includes(el.value)
    )
    const translatedScopes = filteredScopes?.map((el) => t(el.text))

    return (
        <div
            className={`${style.secondFactorCard}`}
            key={requiredFactor + Math.random()}
        >
            <div className={style.secondFactorCard__header}>
                <div className={style.secondFactorCard__header__title}>
                    <p className={cx('buttonSM')}>
                        {t(requiredFactortext || requiredFactor)}
                    </p>
                    {!!backup[0] ? (
                        <Description
                            className={cx('bodyRegularXS neutral800')}
                            text={
                                editVerifier
                                    ? 'editVerifier.step4.backupSecondFactor'
                                    : 'editIssuance.step5.backupSecondFactor'
                            }
                            extraTranslationParams={{
                                backup: translatedScopes,
                            }}
                        />
                    ) : null}
                </div>
                {canEdit && (
                    <div
                        className={style.secondFactorCard__header__button__icon}
                    >
                        <EditIcon
                            className={style.edit}
                            onClick={() => {
                                selectFactor(item), openModal()
                            }}
                            size={20}
                        />
                        <TrashIcon
                            className={style.bin}
                            onClick={() => removeFactor(item)}
                            size={20}
                        />
                    </div>
                )}
            </div>
        </div>
    )
}

export default SecondFactorCard
