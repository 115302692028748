import { t } from 'i18next'
import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { brandLabels, gaImages } from '../../../../assets/globalConstants'
import { licensesSelectors } from '../../../../features/common/licenses'
import { popularLicenseType } from '../../../../features/common/licenses/licenses.constants'
import { sessionSelectors } from '../../../../features/common/session'
import {
    ButtonModel,
    ITierModel,
    ISubscriptionInfo,
} from '../../../../interfaces/interfaces'
import DarkButton from '../../../atoms/buttons/DarkButton/DarkButton'
import LightButton from '../../../atoms/buttons/LightButton/LightButton'
import GaImage from '../../../atoms/Image/Image'
import LightPanel from '../../../atoms/panels/LightPanel/LightPanel'
import Description from '../../../atoms/texts/Description/Description'
import styles from './licenseCard.module.scss'
import cx from 'classnames'
import PrimaryLicenseButton from './primaryLicenseButton/PrimaryLicenseButton'
import {
    getIfProductHasAmount,
    getTierLimitToDisplay,
    getTierLimitsValues,
    isEnterpriseTier,
} from '../../../../utils/licensesUtil'
import GaHoverTooltip from '../../../molecules/tooltips/GaHoverTooltip/GaHoverTooltip'
import {
    TooltipArrowPosition,
    TooltipPosition,
} from '../../../../data/globalVar'

type ILicenseCardProps = {
    isDefaultBrandActive: boolean
    license: ITierModel
    showAllDetails?: boolean
    firstButton?: ButtonModel
    secondButton?: ButtonModel
    period: string
    subscriptionInfo: ISubscriptionInfo
    isCurrentLicense?: boolean
    isNewLicense?: boolean
    lowerThanCurrent: boolean
    currentLicensePeriod: string
    newLicensePeriod: string
    userRole?: string
    className?: string
    showCurrentLicenseTag?: boolean
    noPaymentAction?: (x?) => void
    getPrice: (x?, y?) => number | undefined
    downgradeSubscriptionFunction: (x) => void
    cancelSubscriptionFunction: (x) => void
    tiers?: number
}

type IFeatureProps = {
    feature: any
    label: string
    info?: string
    notAvailableNow?: boolean
    extraTranslationParams?: Object
    tooltipArrowPosition?: TooltipArrowPosition
}

const Feature: React.FC<IFeatureProps> = (props) => {
    const { feature, label, info, notAvailableNow, tooltipArrowPosition } =
        props
    let formattedFeatureLimit = getTierLimitToDisplay(feature)

    return !info ? (
        <li
            className={`${styles.license__features__list__item} ${
                notAvailableNow ? styles.notAvailableNow : ''
            }`}
        >
            {!notAvailableNow && (
                <>
                    <GaImage image={gaImages.check} />
                </>
            )}
            <div
                className={
                    notAvailableNow
                        ? styles.featureNotAvailableNow
                        : styles.license__features__list__item__content
                }
            >
                <span
                    className={cx(
                        'bodyRegularSM neutral1000',
                        styles.featureName
                    )}
                >
                    {formattedFeatureLimit && (
                        <span
                            className={cx(
                                'bodyBoldMD neutral1000 marginRight4'
                            )}
                        >
                            {formattedFeatureLimit}
                        </span>
                    )}
                    {t(label)}
                </span>
            </div>
        </li>
    ) : (
        <li className={styles.license__features__list__item}>
            <GaHoverTooltip
                position={TooltipPosition.bottom}
                positionArrowDirection={
                    tooltipArrowPosition || TooltipArrowPosition.center
                }
                label={
                    <>
                        <GaImage image={gaImages.check} />
                        &nbsp;&nbsp;
                        <span
                            className={cx(
                                'bodyBoldSM neutral1000',
                                styles.featureQuantity
                            )}
                        >
                            {feature}
                        </span>
                        <p
                            className={cx(
                                'bodyRegularSM neutral1000',
                                styles.featureName
                            )}
                        >
                            &nbsp;
                            {t(label)}
                        </p>
                    </>
                }
                info={info || ''}
            />
        </li>
    )
}

const LicenseCard: React.FC<ILicenseCardProps> = (props) => {
    const {
        license,
        showAllDetails,
        firstButton,
        secondButton,
        period,
        isDefaultBrandActive,
        isNewLicense,
        isCurrentLicense,
        lowerThanCurrent,
        currentLicensePeriod,
        showCurrentLicenseTag,
        userRole,
        className,
        noPaymentAction,
        getPrice,
        subscriptionInfo,
        downgradeSubscriptionFunction,
        cancelSubscriptionFunction,
        tiers,
    } = props

    const { t } = useTranslation()
    const selectedPeriod = useSelector(licensesSelectors.getSelectedPeriod)
    const userIsOrgOwner = userRole === 'OrganizationOwner'

    const userPaymnetByStripe =
        subscriptionInfo?.currentTierId &&
        !subscriptionInfo?.paymentOutsideStripe
    const licenseIsEnterprise = !getIfProductHasAmount(license, selectedPeriod)

    const getLabelFirstButton = () => {
        const currentLicensePeriodIsSelectedPeriod =
            currentLicensePeriod === selectedPeriod
        const name = t(license.name || '')
        let buttonLabel = ''
        if (licenseIsEnterprise) {
            buttonLabel = t('subscription.contactUs')
        } else {
            switch (true) {
                case isCurrentLicense && !currentLicensePeriodIsSelectedPeriod:
                    buttonLabel =
                        currentLicensePeriod === 'year'
                            ? t('subscription.switchToMonthlyPlan', {
                                  type: license?.name || '',
                              })
                            : t('subscription.upgradeToYearlyPlan', {
                                  type: license?.name || '',
                              })
                    break
                case !isCurrentLicense:
                    buttonLabel =
                        !lowerThanCurrent && name !== 'starter'
                            ? t('subscription.upgradeTo') +
                              t('subscription.thisPlan')
                            : t('subscription.switchTo') +
                              t('subscription.thisPlan')
                    break
            }
        }

        return buttonLabel
    }

    return (
        <LightPanel
            className={`${styles.license} ${
                isCurrentLicense ? styles.currentLicense : ''
            } ${
                tiers && tiers > 4
                    ? styles.maxTiers
                    : tiers === 3
                    ? styles.threeTiers
                    : ''
            } ${className && className}`}
        >
            {isNewLicense && (
                <div
                    className={cx(
                        styles.license__tag,
                        styles.license__tag__newLicense
                    )}
                >
                    <p className={'bodyBoldCap primary700'}>
                        {t('subscription.newPlan')}
                    </p>
                </div>
            )}
            {isCurrentLicense &&
            (currentLicensePeriod === selectedPeriod ||
                // TODO change this by has trial day
                license?.name === 'Starter' ||
                showCurrentLicenseTag) ? (
                <div className={styles.license__tag}>
                    <p className={cx('bodyBoldCap primary700')}>
                        {t('subscription.currentPlan')}
                    </p>
                </div>
            ) : null}
            <div className={styles.license__header}>
                <p
                    className={cx(
                        'heading6 neutral1000',
                        styles.license__header__title
                    )}
                >
                    {license?.name}
                </p>
                {license?.name === popularLicenseType && (
                    <div className={styles.license__header__popularTag}>
                        <p className={cx('buttonSM')}>
                            {t('subscription.popular')}
                        </p>
                    </div>
                )}
            </div>
            <Description
                className={cx(
                    'bodyRegularSM neutral700',
                    styles.license__description
                )}
                text={license?.description || ''}
            />
            {!licenseIsEnterprise ? (
                <>
                    <p
                        className={cx(
                            'bodyRegularMD neutral700',
                            styles.license__price
                        )}
                    >
                        <span className={cx('bodyRegularSM neutral1000')}>
                            {getPrice(
                                license?.prices,
                                !showCurrentLicenseTag
                                    ? selectedPeriod
                                    : currentLicensePeriod
                            )}
                            €
                        </span>{' '}
                        &nbsp;
                        {'/ ' + t('subscription.' + period)}
                    </p>
                    {/* COMEMNTED because this will be restored in the future
                    {period === 'year' && (
                        <span
                            className={cx(
                                'bodyRegularXS neutral1000',
                                styles.license__save
                            )}
                        >
                            {t('subscription.save10Percent')}
                        </span>
                    )} */}
                </>
            ) : (
                <p
                    className={`${cx(
                        'heading5 neutral1000',
                        styles.license__customPrice
                    )} `}
                >
                    {t('subscription.customPricing')}
                </p>
            )}
            {isDefaultBrandActive ? (
                <div className={styles.license__features}>
                    <div>
                        {!isEnterpriseTier(license) ? (
                            <ul className={styles.license__features__list}>
                                <Feature
                                    feature={
                                        getTierLimitsValues(license)
                                            ?.activeUsers
                                    }
                                    label={'subscription.activeUsers'}
                                />
                                <Feature
                                    feature={
                                        getTierLimitsValues(license)
                                            ?.issuedCredentials
                                    }
                                    label={'subscription.issuedCredentials'}
                                />
                                <Feature
                                    feature={''}
                                    label={'subscription.standardSupport'}
                                />
                            </ul>
                        ) : (
                            <ul className={styles.license__features__list}>
                                <Feature
                                    feature={''}
                                    label={'subscription.customLimits'}
                                />
                                <Feature
                                    feature={''}
                                    label={
                                        'subscription.advancedFunctionalities'
                                    }
                                />
                                <Feature
                                    feature={''}
                                    label={'subscription.premiumSupport'}
                                />
                            </ul>
                        )}
                    </div>
                </div>
            ) : (
                <div className={styles.license__features}>
                    <div>
                        <p
                            className={cx(
                                'bodyBoldXS neutral700',
                                styles.license__features__title
                            )}
                        >
                            {t('subscription.features')}
                        </p>
                        <ul className={styles.license__features__list}>
                            <Feature
                                feature={
                                    getTierLimitsValues(license)
                                        ?.verificationTemplates
                                }
                                label={'subscription.verificationTemplates'}
                            />

                            <Feature
                                feature={
                                    getTierLimitsValues(license)?.activeUsers
                                }
                                label={'subscription.activeUsers'}
                            />
                        </ul>
                    </div>
                    <div>
                        <p
                            className={cx(
                                'bodyBoldXS neutral700',
                                styles.license__features__title
                            )}
                        >
                            {t('subscription.issuanceFeatures')}
                        </p>
                        <ul className={styles.license__features__list}>
                            <Feature
                                feature={
                                    getTierLimitsValues(license)
                                        ?.issuanceTemplates
                                }
                                label={'subscription.issuanceTemplates'}
                            />

                            <Feature
                                feature={
                                    getTierLimitsValues(license)
                                        ?.issuedCredentials
                                }
                                label={'subscription.issuedCredentials'}
                            />
                        </ul>
                    </div>
                    {showAllDetails && (
                        <div>
                            <p className={styles.license__features__title}>
                                {t('subscription.otherFeatures')}
                            </p>

                            <ul className={styles.license__features__list}>
                                <Feature
                                    feature={getTierLimitsValues(license)?.dids}
                                    label={'subscription.dids'}
                                />

                                <Feature
                                    feature={
                                        getTierLimitsValues(license)?.apiKeys
                                    }
                                    label={'subscription.apiKeys'}
                                    extraTranslationParams={{
                                        brand: brandLabels?.brand,
                                    }}
                                />

                                {/* COMEMNTED because this will be restored in the future
                            
                            {!!getTierLimitsValues(license)?.nFactor
                                ?.length && (
                                <li
                                    className={
                                        styles.license__features__list__item
                                    }
                                >
                                    <GaImage image={gaImages.check} />
                                    &nbsp;&nbsp;
                                    <span className={styles.featureName}>
                                        <Trans
                                            i18nKey={
                                                'subscription.nFactorMechanisms'
                                            }
                                            values={{
                                                all: getTierLimitsValues(
                                                    license
                                                )?.nFactor?.join(', '),
                                            }}
                                        />
                                    </span>
                                </li>
                            )}
                            <li
                                className={styles.license__features__list__item}
                            >
                                <GaImage image={gaImages.check} />
                                &nbsp;&nbsp;
                                <span className={styles.featureName}>
                                    <Trans
                                        i18nKey={'subscription.notifications'}
                                        values={{
                                            all: getTierLimitsValues(
                                                license
                                            )?.notifications?.join(', '),
                                        }}
                                    />
                                </span>
                            </li>
                            <li
                                className={styles.license__features__list__item}
                            >
                                <GaImage image={gaImages.check} />
                                &nbsp;&nbsp;
                                <span className={styles.featureName}>
                                    {t('subscription.stadistics')}
                                </span>
                            </li>
                            {!!getTierLimitsValues(license)
                                ?.didPublicCatalogue && (
                                <Feature
                                    feature={''}
                                    label={'subscription.didPublic'}
                                />
                            )}

                            {getTierLimitsValues(license)?.support && (
                                <Feature
                                    feature={
                                        getTierLimitsValues(license)?.support
                                    }
                                    label={'subscription.support'}
                                />
                            )}
                            {getTierLimitsValues(license)?.slas && (
                                <Feature
                                    feature={''}
                                    label={'subscription.slas'}
                                />
                            )}

                            {getTierLimitsValues(license)
                                ?.newSchemaPublicCatalogue && (
                                <Feature
                                    feature={
                                        license?.features
                                            ?.newSchemaPublicCatalogue
                                    }
                                    label={'subscription.slas'}
                                    notAvailableNow
                                />
                            )}
                            {getTierLimitsValues(license)?.customQR && (
                                <Feature
                                    feature={
                                        getTierLimitsValues(license)?.customQR
                                    }
                                    label={'subscription.customQr'}
                                    notAvailableNow
                                />
                            )}
                            {!getTierLimitsValues(license)?.nFactor?.length && (
                                <li
                                    className={`${styles.license__features__list__item} ${styles.notAvailableNow}`}
                                >
                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                    <span className={styles.featureName}>
                                        <Trans
                                            i18nKey={
                                                'subscription.npNFactorMechanisms'
                                            }
                                        />
                                    </span>
                                </li>
                            )}
                            {!getTierLimitsValues(license)
                                ?.didPublicCatalogue && (
                                <Feature
                                    feature={''}
                                    label={'subscription.didPublic'}
                                    notAvailableNow
                                />
                            )}
                            {!getTierLimitsValues(license)
                                ?.newSchemaPublicCatalogue && (
                                <Feature
                                    feature={''}
                                    label={'subscription.newPublicSchemas'}
                                    notAvailableNow
                                />
                            )}
                            {!getTierLimitsValues(license)?.customQR && (
                                <li
                                    className={`${styles.license__features__list__item} ${styles.notAvailableNow}`}
                                >
                                    &nbsp; &nbsp;
                                    {t('subscription.customQr')}
                                </li>
                            )}
                            {!getTierLimitsValues(license)?.support && (
                                <Feature
                                    feature={''}
                                    label={'subscription.support'}
                                    notAvailableNow
                                />
                            )}
                            {!getTierLimitsValues(license)?.slas && (
                                <Feature
                                    feature={''}
                                    label={'subscription.slas'}
                                    notAvailableNow
                                />
                            )} */}
                            </ul>
                        </div>
                    )}
                </div>
            )}
            {firstButton && userIsOrgOwner && userPaymnetByStripe ? (
                !!isCurrentLicense &&
                currentLicensePeriod.toLowerCase() === selectedPeriod ? (
                    !!isDefaultBrandActive ? (
                        <LightButton
                            className={cx(
                                'buttonSM',
                                styles.license__button,
                                !!isCurrentLicense &&
                                    styles.license__buttonCurrent
                            )}
                            functionality={() => {
                                cancelSubscriptionFunction(license)
                            }}
                            disabled={!!subscriptionInfo.cancelAtPeriodEnd}
                            text={t('subscription.cancelSubscription')}
                        />
                    ) : null
                ) : (
                    <PrimaryLicenseButton
                        firstButton={firstButton}
                        isCurrentLicense={isCurrentLicense}
                        license={license}
                        lowerThanCurrent={lowerThanCurrent}
                        currentLicensePeriod={currentLicensePeriod}
                        noPaymentAction={noPaymentAction}
                    />
                )
            ) : null}
            {secondButton && (
                <LightButton
                    className={cx(
                        'buttonSM',
                        styles.license__button,
                        !!isCurrentLicense && styles.license__buttonCurrent
                    )}
                    functionality={secondButton?.function}
                    text={secondButton.label}
                    disabled={false}
                />
            )}
        </LightPanel>
    )
}

export default LicenseCard
