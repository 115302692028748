import cx from 'classnames'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import {
    brandColors,
    brandImages,
    brandLabels,
    gaImages,
} from '../../assets/globalConstants'
import { ReactComponent as CloseIcon } from '../../assets/images/close.svg'
import { setSelectedRoute } from '../../features/common/PanelScafolding/scafoldingActions'
import GaImage from '../atoms/Image/Image'
import styles from './testQR.module.scss'

type ITestQrProps = {
    configId?: string
    appName: string
    appPassword: string
    callBack?: string
    appToken?: string
    show?: boolean
    successRoute?: string
    verifier?: boolean
    v2?: boolean
}

export enum RESULT_STATUS {
    ONGOING = 0,
    SUCCESS = 1,
    FAILED = 2,
}

export const TestQR: React.FC<ITestQrProps> = (props) => {
    const { verifier, show, appToken, configId, successRoute, callBack, v2 } =
        props
    const { t } = useTranslation()
    const qr = useRef(null)
    let gqr: HTMLGatacaQrElement | undefined
    const dispatch = useDispatch()
    let sessionId: string = ''

    let loading = false
    const [sessionError, setSessionErrorState] = useState(false)
    const [serviceError, setServiceErrorState] = useState(false)

    const history = useHistory()

    function navigate(route: string) {
        dispatch(setSelectedRoute(route))
        history.push({
            pathname: route,
            state: { detail: 'pending' },
        })
    }

    const createSession = async (): Promise<{
        sessionId: string
        authenticationRequest?: string
    }> => {
        try {
            let endpoint = verifier
                ? v2
                    ? '/api/v3/sessions'
                    : '/api/v1/sessions'
                : '/api/v3/oidc-issuance/credential-offer'
            let response = await fetch(endpoint, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'jwt ' + appToken,
                },
                // TODO: Add callback in body / group
                body: verifier
                    ? '{"ssiConfigId": "' + configId + '"}'
                    : '{"group": "' + configId + '"}',
                mode: 'cors',
                credentials: 'include',
            })
            let data = await response.json()
            if (verifier) {
                if (v2) {
                    sessionId = data.presentation_definition.id
                    return {
                        sessionId: data.presentation_definition.id,
                        authenticationRequest: data.authentication_request,
                    }
                }
                sessionId = data.id
                return {
                    sessionId: data.id,
                    authenticationRequest: data.authentication_request,
                }
            }
            sessionId =
                getSessionIdFromCredentialOfferUri(data.credential_offer_uri) ||
                ''
            return {
                sessionId: sessionId,
                authenticationRequest: data.credential_offer_uri,
            }
        } catch (error) {
            setSessionErrorState(true)
            throw error
        }
    }

    const getSessionIdFromCredentialOfferUri = (credOfferUri) => {
        let requrl = new URL(credOfferUri)
        let credOffer = requrl.searchParams.get('credential_offer_uri')
        if (credOffer) {
            requrl = new URL(credOffer)
            let sessionId = requrl.pathname.split('/').pop()
            return sessionId
        }
        return
    }

    const checkStatus = async (): Promise<{
        result: RESULT_STATUS
        data?: any
    }> => {
        if (!appToken || sessionError || serviceError || !sessionId) {
            gqr?.stop()
            return { result: RESULT_STATUS.ONGOING }
        }

        const endpoint =
            (verifier
                ? v2
                    ? '/api/v3/sessions/'
                    : '/api/v1/sessions/'
                : '/admin/v1/issuanceRequests/') + sessionId

        let response = await fetch(endpoint, {
            method: 'GET',
            headers: {
                Authorization: 'jwt ' + appToken,
                'Content-Type': 'application/json',
            },
            mode: 'cors',
            credentials: 'include',
        })

        if (verifier) {
            let req = await response
            let ongoing = v2 ? 202 : 204
            return req.status === 200
                ? { result: RESULT_STATUS.SUCCESS }
                : req.status === ongoing
                ? { result: RESULT_STATUS.ONGOING }
                : { result: RESULT_STATUS.FAILED }
        } else {
            let req = await response.json()
            return !req.status || req.status === 'PENDING'
                ? { result: RESULT_STATUS.ONGOING }
                : req.status === 'INVALID'
                ? { result: RESULT_STATUS.FAILED }
                : { result: RESULT_STATUS.SUCCESS }
        }
    }

    const createSessionSuccess = (newdata: any) => {
        callBack
        !!successRoute && navigate(successRoute)
    }

    const createSessionError = (error: any) => {
        setServiceErrorState(true)
    }

    useEffect(() => {}, [show])

    useEffect(() => {
        if (!!appToken && !sessionError && !serviceError) {
            if (qr != null && qr.current != null) {
                gqr = qr.current! as HTMLGatacaQrElement
                gqr.createSession = createSession
                gqr.qrModalTitle = t('createVerifier.testReady')
                gqr.qrModalDescription = t('createVerifier.scanQR')
                gqr.checkStatus = checkStatus
                gqr.successCallback = createSessionSuccess
                gqr.errorCallback = createSessionError
            }
        }
    }, [appToken])

    useEffect(() => {
        if (gqr && typeof gqr?.createSession === 'function') {
            gqr.autostart = true
        }
    }, [gqr?.createSession])

    return (
        <div className={styles.testQr}>
            {!loading ? (
                // @ts-ignore
                <gataca-qr
                    callback-server={process.env.REACT_APP_CONNECT_HOST}
                    ref={qr}
                    polling-frequency="3"
                    qr-modal-title={t('public.testReady')}
                    qr-modal-description={
                        verifier
                            ? t('public.scanToConnect')
                            : t('public.scanToDownload')
                    }
                    logo-size="0.33"
                    hide-brand-title={true}
                    logo-src={brandImages?.brandLogo?.src}
                    qr-code-expired-label={t('login.qrExpired')}
                    credentials-not-validated-label={t(
                        'login.userCredNotValidated'
                    )}
                    click-inside-box-label={t('login.clickInsideBoxLabel')}
                    refresh-qr-label={t('login.refreshQrLabel')}
                    scan-qr-label={t('login.scanQrLabel')}
                    user-not-scan-in-time-error-label={t(
                        'login.userNotScanInTimeErrorLabel'
                    )}
                    autostart={
                        typeof gqr?.createSession !== 'function'
                            ? 'false'
                            : 'true'
                    }
                    creds-not-validated-error-label={t(
                        'login.credsNotValidatedErrorLabel'
                    )}
                    modal-title-color={brandColors?.primaryColor}
                    failed-login-error-label={t('login.failedLoginErrorLabel')}
                    success-login-label={t('login.successLoginLabel')}
                    by-brand-label={t('login.byBrandLabel', {
                        brand: brandLabels?.brand,
                    })}
                    waiting-start-session-label={t(
                        'login.waitingStartSessionLabel'
                    )}
                    qr-role="connect"
                    v-2={v2}
                />
            ) : !sessionError && !serviceError && !!appToken ? (
                <div className={styles.loadingContent}>
                    <div>
                        <GaImage image={gaImages.loadingCircle} />
                    </div>
                    <p>{t('createVerifier.generatingTest')}</p>
                </div>
            ) : (
                <div className={styles.errorContent}>
                    <div>
                        <CloseIcon />
                    </div>
                    <p className={cx('margin-top')}>Error</p>
                    <p>
                        {t(
                            sessionError
                                ? 'createVerifier.generatingTestError'
                                : 'createVerifier.readingError'
                        )}
                    </p>
                </div>
            )}
        </div>
    )
}

export default TestQR
